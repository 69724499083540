export interface IMessage {
    text: string;
}

interface IMessageProvider {
    () : string;
}

export class MessageIndex {
    private index = -1;
    private fixedMessages: string[] = [];
    private funnyMessages: IMessageProvider[] = [ this.currentTime() ];

    addFixedMessage(text: string) {
        this.fixedMessages.push(text);
    }

    addFunnyMessage(text: string) {
        this.funnyMessages.push(function () {
            return text;
        });
    }

    findMessage(): IMessage {
        const text = this.fixedMessages[++this.index] || this.findFunnyMessage();

        return {
            text,
        };
    }

    private currentTime() {
        return function () {
            const date = new Date();

            return [
                short(date.getHours()),
                short(date.getMinutes()),
                short(date.getSeconds()),
            ].join(':');
        };
    }

    private fn(text: string) {
        return function () {
            return text;
        };
    }

    private findFunnyMessage(): string {
        const index = Math.floor(Math.random() * this.funnyMessages.length);
        const funnyMessage = this.funnyMessages[index];

        return funnyMessage();
    }
}

function short(num: number, filler = '00') {
    return (filler + num).slice(-filler.length);
}