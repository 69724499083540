import { TimeControl } from './TimeControl'
import { MessageIndex } from './MessageIndex'

export class Program {
    async run() {
        // await Utils.delay();
        if (window.matchMedia("(prefers-color-scheme: dark)")) {
            document.body.classList.toggle('dark-mode');
        }

        const messageIndex = new MessageIndex();
        const task = fetch(location.origin + '/config/data');
        const dates = await task.then(x => x.json()).then(config => {
            const dates = new Array<Date>();
            for (const item of config.dates) {
                const date = new Date(item);
                dates.push(date);
            }

            for (const text of config.messages.fixed) {
                messageIndex.addFixedMessage(text);
            }

            for (const text of config.messages.random) {
                messageIndex.addFunnyMessage(text);
            }
            return dates;
        });

        const control = new TimeControl(dates); 5;

        setInterval(() => control.update(), 1000);

        const audio: HTMLAudioElement = document.querySelector('#click-audio')!;
        const button: HTMLButtonElement = document.querySelector('#funny-button')!;

        button.addEventListener('click', function () {
            const div = document.createElement('div');
            const msg = messageIndex.findMessage();

            if (audio.fastSeek) {
                audio.fastSeek(0);
                audio.play();
            }

            div.classList.add('flyout');
            div.innerText = msg.text;
            div.addEventListener('animationend', function () {
                div.remove();
            });

            if (Math.floor(Math.random() * 100) == 1) {
                window.open('https://www.youtube.com/watch?v=dQw4w9WgXcQ')
            }

            button.parentElement?.append(div);
        });
    }
}