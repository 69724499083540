import { Utils } from './Utils'

export class TimeControl {
    private state = 0;
    private weeks = 0;
    private start: Date | null = null;
    private until: Date | null = null;

    private appNr: HTMLElement = document.querySelector('#app-nr')!;
    private appMain: HTMLElement = document.querySelector('#app-main')!;
    private appTime: HTMLElement = document.querySelector('#app-time')!;

    private states = [
        "mode-none",
        "mode-wait",
        "mode-live",
    ];

    constructor(private dates: Date[]) {
    }

    update() {
        // this.ensureState(2);
        // return;
        const date = new Date();

        if (this.start == null || this.until == null || this.until < date) {
            this.find();
        }

        if (this.start! > date) {
            const TicksPerSecond = 1000;

            const start = this.start!;
            const difference = Utils.date_diff(start, date, TicksPerSecond);

            const f1 = 1;
            const f2 = f1 * 60;
            const f3 = f2 * 60;
            const f4 = f3 * 24;

            const text = [
                Math.floor(difference / f4) % 7,
                Math.floor(difference / f3) % 24,
                Math.floor(difference / f2) % 60,
                Math.floor(difference / f1) % 60,
            ].map(x => Utils.fixedString(x));

            this.appNr.innerText = Utils.ordinal_suffix(this.weeks + 1);
            this.appTime.innerText = text.join(':');

            this.ensureState(1);
            return;
        }

        if (this.until! > date) {
            if (this.state != 2) {
                const text = [
                    0,
                    0,
                    0,
                    0,
                ].map(x => Utils.fixedString(x));

                this.appNr.innerText = Utils.ordinal_suffix(this.weeks + 1);
                this.appTime.innerText = text.join(':');
            }

            this.ensureState(2);
            return;
        }
    }

    private ensureState(state: number) {
        if (this.state == state) {
            return;
        }

        const classList = this.appMain.classList;

        this.states.forEach((v, i) => {
            const want = (i == state);
            const have = classList.contains(v);

            if (want == have) {
                return;
            }

            if (want || have) {
                classList.toggle(v);
            }
        });

        this.state = state;
    }

    private find() {
        const now = new Date();
        const TicksPerWeek = 1000 * 60 * 60 * 24 * 7;
        const [first] = this.dates;
        const [begin] = this.dates.slice(-1);

        let start = begin;
        if (start < now) {
            start = now;
            start.setHours(begin.getHours());
            start.setMinutes(begin.getMinutes());
            start.setSeconds(begin.getSeconds());

            const diffDays = (begin.getDay() - now.getDay() + 7) % 7;

            start.setDate(now.getDate() + diffDays);
        }

        const until = new Date(start);

        until.setDate(until.getDate() + 1);

        until.setHours(0);
        until.setMinutes(0);
        until.setSeconds(0);

        const weeks = Utils.date_diff(start, first, TicksPerWeek);

        this.until = until;
        this.start = start;
        this.weeks = weeks;
    }
}
