export class Utils {
    static fixedString(input: any, pad = '00') {
        return (pad + input).slice(-pad.length);
    }

    static delay(delay = 2000) {
        return new Promise((res) => {
            setTimeout(res, delay);
        });
    }

    static date_diff(a: Date, b: Date, f = 1000) {
        return Math.floor((a.getTime() - b.getTime()) / f);
    }

    static ordinal_suffix(i) {
        let j = i % 10, k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }
}
